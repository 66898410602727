//
// Forms.scss
//




[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left
      /*rtl: right*/
    ;
  }
}

.react-toast-notifications__container {
  z-index: 999999999999999999999999999999999;
}

.form-check {
  position: relative;
  text-align: left
    /*rtl: right*/
  ;
}

.react-select-search * {
  overflow: auto;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}



// checkbox

.form-checkbox-outline {

  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        /*rtl: -4px */
        font-size: 16px;
        color: $dark;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    background-color: $card-bg;
    position: relative;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type=checkbox] {
        background-image: none;

      }

      &:after {
        position: absolute;
        content: '';
        top: 3px !important;
        left: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}



// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;

        &:after {
          background-color: $value;
        }
      }
    }
  }
}

.form-check,
.form-check-input,
.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;

  .form-check-input {
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}


.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;

  .form-check-input {
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

// transfer list

.rdl-filter,
.rdl-control {
  background-color: $input-bg;
  border-color: $input-border-color;
  color: $input-color;
}

.react-dual-listbox {
  button {
    color: $dark;
    border-color: $border-color;
    background-color: $light;
  }

  input,
  select {
    &:disabled {
      background-color: $input-disabled-bg;
    }
  }
}

.profile-image-preview {
  max-width: 100%;
  max-height: 100%;
  // width: 100%;
  object-fit: cover;
  height: auto;
  object-position: center;
}

.rdl-move {

  .form-check-input,
  .form-check-input:focus {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>"));
  }
}
.input-group .btn.searchbtn {
  border: 1px solid var(--bs-input-border-color);
  padding: 6px 12px;
  max-height: 32px;
}
#searchbox.form-control {
  padding: 0 0.75rem;
  max-height: 32px;
}