//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
    a {
        color: #0091EA;
    }

    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }

    &.active {
        a {
            // font-weight: 600 !important;
        }
    }
}