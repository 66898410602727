.table--isloading {
    height: 500px;
    border-radius: 4px;
    overflow: hidden;
    /* margin-bottom: 15px; */
}

.table--isloading .loading-image {
    height: 500px;
    background-image: -webkit-linear-gradient(left, #ececec 30%, #f4f4f4 60%, #ececec 80%);
    background-image: -o-linear-gradient(left, #ececec 30%, #f4f4f4 60%, #ececec 80%);
    background-image: linear-gradient(90deg, #ececec 30%, #f4f4f4 60%, #ececec 80%);
    background-size: 30%;
    -webkit-animation: shine-loading-image 2s infinite ease-in-out;
    animation: shine-loading-image 2s infinite ease-in-out;
}


@-webkit-keyframes shine-loading-image {
    0% {
        background-position: 0%;
    }


    100% {
        background-position: 100%;
    }
}


@keyframes shine-loading-image {
    0% {
        background-position: 0%;
    }


    100% {
        background-position: 100%;
    }
}