//
// _table.scss
//

.table {
  width: 100%;

  th {
    font-weight: $font-weight-bold;
    background: tint-color($primary, 90%);
  }

  thead {
    background: #f5fcf9;

    tr {
      td {
        padding: 15px 20px;

        &.action-columns {
          position: -webkit-sticky;
          position: sticky;
          // z-index: 999;
          width: 100px !important;
          min-width: 100px !important;
          background: #f5fcf9;
          // opacity: 1;
          right: 0px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px 20px;
        background: rgb(255, 255, 255);
        overflow: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        .form-check {
          margin-bottom: 0;
          min-height: 0.813rem;
          input.form-check-input {
            margin-top: 0;
          }
        }
        &.skelton-columns {
          padding: 0px !important;
        }

        &.action-columns {
          position: absolute;
          // position: sticky;
          display: flex;
          min-width: 100px !important;
          align-items: center;
          justify-content: center;
          color: 000;
          // z-index: 0;
          right: 0px;
          // overflow: unset;
          // white-space: unset;
          // text-overflow: unset;

          .dropdown-menu.show {
            top: 5px !important;
            z-index: 9999;
          }
        }
      }
      &:nth-child(2n) {
        td {
          background: #f8fafb;
        }
      }
    }
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

.table-responsive.react-table {
  min-height: calc(100vh - 280px);
}

.table-loader {
  overflow: hidden;
  width: 100%;
  height: 500px;
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-loader:before {
  visibility: visible;
  display: table-caption;
  content: " ";
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #55c595 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #55c595);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: load 2s infinite linear;
}

@keyframes load {
  to {
    transform: rotate(2turn);
  }
}
.maxActionHeight {
  max-height: 19.5px;
}
td.checkBox-width {
  width: 75px !important;
  min-width: 75px !important;
}

td.checkBox-width > .form-check.form-check-inline {
  margin: 0 !important;
}

table.table p {
  margin: 0 !important;
}