/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/chips";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";
@import "custom/components/spinner";
@import "custom/components/animations";
// Plugins

@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";
@import "custom/pages/servicedashboard";
@import "custom/pages/dashboard";

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 10px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(160, 159, 159);
}

.text-link {
    font-weight: normal;
    cursor: pointer;
    color: #74c398;
}
.textWrap {
    word-break: break-all;
    white-space: initial !important;;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pointer {
    cursor: pointer;
}

.searchbtn {
    background: white;
    border: 1px solid var(--bs-input-border-color);
    border-left: 0px;
    color: var(--bs-input-border-color)
}

.note-title {
    font-weight: 600;
    color: #55C595;
    font-size: 15px;
}

.jodit-status-bar {
    display: none
}
.react-select-search__menu .react-select-search__menu-list {
    max-height: 220px !important;
}
.detailBox {
    min-height: calc(100vh - 246px);
}
.table-responsive.react-table.innerTableHeight {
    min-height: calc(100vh - 350px);
}
.table-responsive.react-table.innerTableHeightNoSort {
    min-height: calc(100vh - 310px);
}
.log-card .right .desc p ul {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        p {
            margin: 0;
        }
    }
}
.min-height-block {
    min-height: calc(100vh - 278px);
}
.react-toast-notifications__toast__content {
    min-height: unset;
}
.toast-header .btn-close {
    width: 4px;
    position: relative;
    height: 4px;
    right: 6px;
    filter: invert(1);
    opacity: 1 !important;
}
.toast-header strong {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.toast .bg-white.toast-body {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}
.toast-container.position-absolute {
    position: fixed !important;
}
a.text-reset.notification-item .d-flex > svg {
    width: 16px;
    height: 16px;
}
a.text-reset.notification-item .d-flex {
    .flex-grow-1 p > i {
        margin-right: 5px;
    }
}