.pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px;
        width: 24px;
        margin-right: 2px;
        margin-left: 2px;
        height: 24px;
        border: 1px solid #CACEDA;
        border-radius: 6px;

        &:hover {
            border: 1px solid tint-color($primary, 40%);



            cursor: pointer;
        }

        &.selected {
            border: 1px solid $primary;


        }

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }

        .arrow::before {
            position: relative;
            /* top: 3pt;
                Uncomment this to lower the icons as requested in comments*/
            content: '';
            /* By using an em scale, the arrows will size with the font */
            display: inline-block;
            width: 0.4em;
            height: 0.4em;
            border-right: 0.12em solid rgba(0, 0, 0, 0.87);
            border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }

        .arrow::before {
            border-right: 0.12em solid rgba(0, 0, 0, 0.43);
            border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }

        &.disabled {
            pointer-events: none;

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }

        .arrow.left {
            transform: rotate(-135deg) translate(-50%);
        }

        .arrow.right {
            transform: rotate(45deg);
        }
    }


}

.pagination {
    .form-select {
        display: block;
        width: 100%;
        padding: 5px 25px 5px 4px;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: var(--bs-gray-700);
        background-color: var(--bs-custom-white);
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 5px center;
        background-size: 8px 10px;
        border: 1px solid var(--bs-input-border-color);
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        appearance: none;
    }
}