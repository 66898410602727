$color1: #55c595;
$color2: #222222;

@mixin title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color1;
}
@mixin data {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: $color2;
}
.summaryData {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  .sumarry {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color2;
    margin-bottom: 20px;
  }
  .summarydropdown {
    text-align: end;
  }
  .list {
    padding: 16px;
    height: 100%;
    margin: 0;
    .listtitle {
      @include title;
    }
    .listvalue {
      @include data;
    }
    .listspace {
      padding-top: 10px;
      border-top: 1px solid #e1e1e1;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      .listspacebottom {
        margin-bottom: 0px;
        .listsubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #555555;
        }
        .listsubvalue {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: right;
          color: #555555;
        }
      }
    }
  }
  .LeadsDashboard {
    background: #ffffff;
    padding: 24px;
    border: 1px solid rgba(85, 197, 149, 0.26);
    border-radius: 8px;
    .title {
      @include title;
      font-weight: 700;
    }
    .leadlist {
      display: flex;
      justify-content: space-between;
      .leadsubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
      }
      .leadvalues {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .Dashboardchart {
    margin-top: 16px;
    padding: 30px;
    gap: 10px;
    background: #f5fcf9;
    box-shadow: 0px 8px 48px rgba(47, 47, 47, 0.07);
    border-radius: 10px;
    .chartdatas {
      background: #ffffff;
      border: 1px solid rgba(85, 197, 149, 0.26);
      border-radius: 8px;
      padding: 24px;
      gap: 16px;
    }
  }
}
.gap10 {
  gap: 10px 0;
}
select.form-control.maxWidth {
  max-width: 100px;
  appearance: auto;
  max-height: 32px;
  min-height: 32px;
  line-height: 32px;
  padding: 0 6px;
}

select.form-control.maxWidth.maxContentWidth {
  max-width: max-content;
}

.ml-auto {
  margin-left: auto;
  margin-right: 0;
}
input.form-control.maxWidth {
  max-width: 92px;
  position: absolute;
  right: 0;
  min-height: 32px;
  max-height: 32px;
  z-index: 1;
}
.customPicker {
  top: 32px !important;
  .react-datepicker__triangle {
    left: auto !important;
  }
}
.listIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .iconBox {
    width: 24px;
    height: 24px;
    background: #f8fafb;
    border-radius: 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
