//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}gray-700);
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
    color: var(--#{$prefix}gray-700);
    font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  border-bottom: 2px solid var(--#{$prefix}gray-300);

  .nav-item {
    position: relative;
    color: var(--#{$prefix}dark);
    .nav-link {
      border: none;

      &::after {
        content: "";
        background: #55c595;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: #55c595;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}

.vertical-collpsed {
  .vertical-menu {
    position: fixed !important;
    #sidebar-menu > ul > li:hover > a {
      position: relative;
      width: calc(190px + 70px);
      color: #556ee6;
      background: linear-gradient(0deg, #55c595, #55c595), #55c595 !important;
      transition: none;
      border-radius: 4px;
    }
    #sidebar-menu > ul > li > a {
      padding: 0px 8px !important;
      height: 32px !important;
      transition: none;
      min-height: 32px !important;
      width: 32px;
      margin: 0 4px;
      display: flex;
      align-items: center;
    }
    #sidebar-menu > ul > li {
      position: relative;
      white-space: nowrap;
      margin-bottom: 8px;
    }
    #sidebar-menu > ul > li > a i {
      font-size: 16px !important;
      margin-left: 0 !important;
    }
  }
}
#sidebar-menu ul li.mm-active a.active {
  background: linear-gradient(0deg, #55c595, #55c595), #55c595;
  border-radius: 4px;
}

.metismenu li {
  display: block;
  width: 100%;
  padding: 0 14px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #55c595 !important;
}

.nav-tabs-custom {
  .nav-link {
    padding: 20px 16px;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #55c595;
  text-decoration: none;
}
