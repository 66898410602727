//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

// example 1

.btn-group-example {
    .btn {
        position: relative;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &:before {
            content: "OR";
            position: absolute;
            font-size: 10px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border-radius: 50%;
            background-color: $gray-600;
            color: $light;
            border: 1px solid $white;
            left: -12px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}

// Example 2

.btn-label {
    position: relative;
    padding-left: 44px;

    .label-icon {
        position: absolute;
        width: 32px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba($white, 0.1);
        border-right: 1px solid rgba($white, 0.1);
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-light {
        .label-icon {
            background-color: rgba($dark, 0.1);
            border-right: 1px solid rgba($dark, 0.2);
        }
    }
}

.btn-group-vertical {
    label {
        margin-bottom: 0px;
    }
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
        border-color: transparent;
    }
}

@each $color,
$value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

.like-btn {
    &.active {
        .bx-heart {
            color: $danger;

            &:before {
                content: "\ec8f";
            }
        }
    }
}

.btn-primary {
    background: #55c595 !important;
    border-color: #55c595 !important;
    color: #fff !important;

    &:hover {
        background: #55c595 !important;
        border-color: #55c595 !important;
        opacity: 0.8;
    }

    &:focus {
        background: #55c595 !important;
        border-color: #55c595 !important;
    }
}

.btn-icon {
    min-width: 32px;
    max-width: 32px;
    max-height: 32px;
    min-height: 32px;
    border: 1px solid #8f8f8f;
    border-radius: 6px;
    color: #222222;
    background: transparent;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #55c595 !important;
        border-color: #55c595 !important;
        opacity: 0.8;
    }

    &:focus {
        background: #55c595 !important;
        border-color: #55c595 !important;
    }
}

.page-title-right .btn {
    margin-left: 8px;
}

.btn.btn-secondary {
    border: 1px solid #8f8f8f;
    border-radius: 6px;
    color: #222222;
    background: transparent;
}

.btn.btn-primary,
.btn.btn-secondary {
    min-width: 72px;
    max-height: 32px;
    min-height: 32px;
    line-height: 32px;
    padding: 0 16px;
}

.btn.btnSml {
    max-height: 24px;
    padding: 0;
    min-height: 24px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    min-width: 60px;
}

.btn-warning {
    background: #dc7676 !important;
    border: 1px solid #dc7676 !important;
    color: #fff !important;

    &:hover {
        background: #dc7676 !important;
        border: 1px solid #dc7676 !important;
        opacity: 0.8;
    }

    &:focus {
        background: #dc7676 !important;
        border: 1px solid #dc7676 !important;
        opacity: 0.8;
    }
}

.btn-danger {
    background: #f46a6a !important;
    border-color: #f46a6a !important;
    color: #fff !important;

    &:hover {
        background: #f46a6a !important;
        border-color: #f46a6a !important;
        opacity: 0.8;
    }

    &:focus {
        background: #f46a6a !important;
        border-color: #f46a6a !important;
    }
}