// service dashboard
$color1: #222222;
$color2: #55C595;
.servicedashboard{
    .leadbystatus{
        background: #F5FCF9;
        border: 1px solid rgba(85, 197, 149, 0.26);
        border-radius: 8px;
        padding: 24px;
        .title{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $color1
           
        }
        .satuslist{
            display: flex;
            justify-content: space-between;
            .subtitle{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #222222;
                margin-bottom: 6px;
            }
            .values{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #222222;
            }
        }
        .leads{
            padding: 20px 16px;
            gap: 24px;
            background: #FFFFFF;
            box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            .leadslist{
                display: flex;
                justify-content: space-between;
                .subtitle{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: $color2;
                    margin-bottom: 6px;
                }
                .count{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 20px;
                    color: $color1;
                    margin-bottom: 6px;
                }
                .amount{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 20px;
                    color:#909090
                }
            }
        }
    }

}