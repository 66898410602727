.chips {
  padding: 2px 4px;
  border-radius: 7px;
  color: #fff;

  &.active {
    background: #5559c5;
  }

  &.bronze {
    background: #32a6cb;
  }

  &.qualified {
    background: #32a6cb;
  }

  &.working {
    background: #32a6cb;
  }

  &.nurturing {
    background: #32a6cb;
  }

  &.unqualified {
    background: #32a6cb;
  }

  &.new {
    background: #32a6cb;
  }

  &.contactinfuture {
    background: #32a6cb;
  }

  &.contacted {
    background: #32a6cb;
  }

  &.junklead {
    background: #32a6cb;
  }

  &.lostlead {
    background: #32a6cb;
  }

  &.notcontacted {
    background: #32a6cb;
  }

  &.prequalified {
    background: #32a6cb;
  }

  &.notqualified {
    background: #32a6cb;
  }

  &.attemptedtocontact {
    background: #32a6cb;
  }

  &.display_id {
    background: #525252;
  }

  &.inactive {
    background: $danger;
  }

  &.documentspending {
    background: #32a6cb;
  }

  &.sendforlogin {
    background: #32a6cb;
  }

  &.approved {
    background: #32a6cb;
  }

  &.completed {
    background: #34c38f;
  }

  &.rejected {
    background: #32a6cb;
  }

  &.processing {
    background: #fe7800;
  }

  &.closed {
    background: #555c5f;
  }

  &.resolved {
    background: #34c38f;
  }

  &.created {
    background: #32a6cb;
  }

  &.verificationfailed {
    background: $danger;
  }

  &.verified {
    background: #34c38f;
  }

  &.failed {
    background: $danger;
  }

  &.pending {
    background: #32a6cb;
  }

  &.converted {
    background: #32a6cb;
  }
}
