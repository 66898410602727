.form-select {
  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid #5eb0fb !important;
    outline: none !important;
  }
}
.text-tranc {
  width: 200px;
  /* width: 250px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.link-default {
  a {
    cursor: default !important;
  }
}
.min-250 {
  min-height: 250px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.form-control {
  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid #5eb0fb !important;
    outline: none !important;
  }

  &.required {
    border-left: 4px #ffa0a0 solid;
    border-radius: 0.25rem;
  }
  &:disabled {
    background-color: #fafafa !important;
    border: 1px solid #edeef1 !important;
    &.required {
      border-left: 4px #ffa0a0 solid !important;
      border-radius: 0.25rem;
    }
  }
}

.required {
  border-left: 4px #ffa0a0 solid;
}

.image-icon {
  width: 182px;
  height: 120px;
  position: relative;
  z-index: 1;

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .defaultBlock {
    background: #c4c4c4;
    border: 1px solid #edeef1;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.imageUpload {
  width: 178px;
  height: 178px;
  position: relative;
  z-index: 1;

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .defaultBlock {
    background: #c4c4c4;
    border: 1px solid #edeef1;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

label.control-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #555555;
  font-weight: 500;
}

span.success {
  color: #42a75e;
  display: flex;

  i.bx {
    font-size: 20px;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

span.error.rotate45 {
  display: flex;

  i.bx {
    font-size: 20px;
    transform: rotate(45deg);
    margin-right: 5px;
    top: -1px;
  }
}

a.linkText {
  color: #0077be;
  text-decoration: underline !important;
}

.modalUpload .imageUpload {
  width: 100%;
  height: 140px;
}

.modal-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
}

.card-title {
  &.small {
    font-size: 12px;
  }
}

.custCard {
  background: #ffffff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  .card-body {
    padding: 12px;

    .headBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 9px;

      .control-label {
        margin: 0;
      }
    }

    .footBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;

      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #555;
      }
    }
  }
}

.common-bg {
  background: #edeef185 !important;
}

.card .col-lg-9 {
  border-right: 1px solid #edeef1;
}

.subCard-body {
  background: #ffffff;
  box-shadow: none;
  border-radius: 0px;
}

.border-right-none {
  border-right: none !important;
}

@media (min-width: 992px) {
  .col-lg-2 {
    flex: 0 0 auto;
    width: 20%;
  }
}

.log-card {
  .left {
    font-weight: 400;
    font-size: 12px;
    border-right: 1px solid #edeef1;
    padding: 6px calc(var(--bs-gutter-x) * 0.5);

    * {
      line-height: 18px;
    }

    .label {
      color: #22222280;
    }

    .value {
      color: #222222;
    }
  }

  .right {
    padding: 6px calc(var(--bs-gutter-x) * 0.5);
    .event {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: #55c595;
    }

    .desc {
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
    }

    .foot {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      .val {
        color: #222222;
      }
    }
  }
}
.white-card {
  background: #ffffff !important;
  box-shadow: 0px 8px 48px rgba(47, 47, 47, 0.07);
  border-radius: 8px;
}
.subCard {
  background: #ffffff;
  /* Shadow Medium */
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 16px;
}

.leadbystatus.bg-none.m-0 {
  background: #fff;
}
.text-transparent {
  color: transparent;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #82c9a385;
  }
  100% {
    background-color: #bcf0d4;
  }
}

.skeleton-text {
  width: 100%;
  height: 20px;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #74c3a1a3;
  border-radius: 5px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.servicedashboard .leadbystatus .leads .leadslist > div:first-child {
  padding-left: 60px;
}

.servicedashboard .leadbystatus .leads .leadslist > div:last-child {
  padding-right: 60px;
}
